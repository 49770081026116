import {
  defaultExperimentValues,
  ExperimentValueTable,
  FeatureFlag,
  nonFatalAssert,
  redirectQueryParam
} from 'notability-services-common-universal'
import {AnalyticsObserver, AnalyticsValue, convertToAnalyticsString} from 'notability-services-common-frontend'
import {GalleryContextContents, GalleryContextSSRData} from './GalleryContextSSR'
import React, {createContext, useContext, useMemo, useState} from 'react'

import {config} from '../config'
import {useCustomRouter} from './useCustomRouter'
import {ParsedUrlQuery} from 'querystring'

export type FeatureFlagSubset = Partial<Record<FeatureFlag, boolean>>

export function useLoggedIn() {
  const {userId, logIn, logOut} = useContext(GalleryContext)
  return {loggedIn: !!userId, userId, logIn, logOut}
}

export function useUserScreenname() {
  const {screenname, setScreenname} = useContext(GalleryContext)
  return {screenname, setScreenname}
}

export function useEmailVerifiedAt() {
  const {emailVerifiedAt} = useContext(GalleryContext)
  return {emailVerifiedAt}
}

export function useEmail() {
  return useContext(GalleryContext).email
}

export function useExperimentValues(): ExperimentValueTable {
  return {
    ...defaultExperimentValues,
    ...useContext(GalleryContext).valueOverrides
  }
}

export function useUserId() {
  return useContext(GalleryContext).userId
}

/**
 * Add stateful data/updaters here
 */
function useMapSSRDataToContents(data: GalleryContextSSRData): GalleryContextContents {
  const [screenname, setScreenname] = useState<string | null>(data.screenname)
  const [email, setEmail] = useState<string | null>(data.email)
  const [emailVerifiedAt, setEmailVerifiedAt] = useState<string | null>(data.emailVerifiedAt)
  const [userId, setUserId] = useState<string | null>(data.userId)

  return {
    ...data,
    // Any client-mutable state needs to be re-added here to override the state from server:
    userId,
    screenname,
    email,
    emailVerifiedAt,
    logIn: (newUserId: string, newScreenname: string, newEmail: string, newEmailVerifiedAt: string | null) => {
      setUserId(newUserId)
      setScreenname(newScreenname)
      setEmail(newEmail)
      setEmailVerifiedAt(newEmailVerifiedAt)
    },
    logOut: () => {
      setUserId(null)
      setEmail(null)
      setEmailVerifiedAt(null)
      setScreenname(null)
    },
    setScreenname: (newScreenname) => setScreenname(newScreenname ?? null)
  }
}

/**
 * New default values must be added here
 */
const GalleryContext = createContext<GalleryContextContents>({
  userId: null,
  screenname: null,
  email: null,
  emailVerifiedAt: null,
  logIn: () => {},
  logOut: () => {},
  setScreenname: () => {},
  valueOverrides: defaultExperimentValues,
  experimentGroups: {}
})

function getPageProperties(pathname: string, query: ParsedUrlQuery): Record<string, AnalyticsValue> {
  const routes = pathname.split('/').filter((p) => p.length > 0)
  const pageProperties: Record<string, AnalyticsValue> = {
    'Page Type': 'Unknown'
  }
  const lastRoute = routes[routes.length - 1]
  if (lastRoute) {
    pageProperties['Page Type'] = convertToAnalyticsString(lastRoute)
  } else {
    pageProperties['Page Type'] = 'Login'
  }

  const plan = typeof query?.plan === 'string' ? query.plan : undefined
  if (plan) {
    pageProperties['Stripe Plan ID'] = plan
  }

  const redirectParam = typeof query[redirectQueryParam] === 'string' ? query[redirectQueryParam] : undefined
  if (redirectParam) {
    pageProperties['Redirect To'] = convertToAnalyticsString(redirectParam)
  }

  return pageProperties
}

/**
 * This component wrapper must be applied to every Page component, along with `sspWithGalleryContext`.
 */
export function ComponentWithGalleryContext<P>(Component: React.ComponentType<P>) {
  const OuterPage = (props: P & GalleryContextProps) => {
    nonFatalAssert(!!props._galleryContext, 'Gallery Context Data missing when expected')
    const {userId, email, experimentGroups} = props._galleryContext
    const router = useCustomRouter()

    const pageProperties = useMemo(
      () => (typeof location === 'undefined' ? {} : getPageProperties(location.pathname, router.query)),
      [router]
    )

    return (
      <GalleryContext.Provider value={useMapSSRDataToContents(props._galleryContext)}>
        <AnalyticsObserver
          userId={userId}
          email={email}
          loggedIn={!userId}
          experimentGroups={experimentGroups}
          pageProperties={pageProperties}
          inSwiftApp={false}
          frontendService={'Login'}
          autoOptRegisteredUsers={true}
        >
          <Component {...props} />
        </AnalyticsObserver>
      </GalleryContext.Provider>
    )
  }

  // This will ensure that all top-level pages are wrapped like this.
  // Note: This assumes that ComponentWithGalleryContext is the outermost Higher-Order Component that is being exported
  if (config.LOCAL_DEV) {
    ;(OuterPage as any)['__isComponentWithGalleryContext'] = true
  }

  return OuterPage
}

export interface GalleryContextProps extends React.ComponentPropsWithoutRef<'div'> {
  _galleryContext: GalleryContextSSRData
}
